﻿@import '~@angular/material/theming';
@include mat-core();

body {margin: 0}

$mat-primary-scheme: (
  100: #239edc,
  500: #1e89bf,
  700: #176891,
  contrast: (
    100: rgba(255,255,255,0.87),
    500: #ffffff,
    700: #ffffff,
  )
);
$mat-accent-scheme: (
  100: #e0f6cb,
  500: #7cda24,
  700: #5ca21b,
  contrast: (
    100: rgba(255,255,255,0.87),
    500: rgba(0,0,0,0.87),
    700: #ffffff,
  )
);

$mat-primary-palette: mat-palette($mat-primary-scheme);
$mat-accent-palette: mat-palette($mat-accent-scheme);

$app-light-theme: mat-light-theme($mat-primary-palette, $mat-accent-palette);

@include angular-material-theme($app-light-theme);

// following section is for dark mode.
// use appropriate class name for darkMode
$app-dark-theme: mat-dark-theme($mat-primary-palette, $mat-accent-palette);
.darkMode {
  @include angular-material-theme($app-dark-theme);
}
