/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~src/mbg-theme.scss";

$primary: mat-palette($mat-primary-scheme, 500);

.mbg-background-primary {
  background-color : mat-color($primary, default);
}

.mbg-snackbar-bottom-margin {
  margin-bottom: max(8px, env(safe-area-inset-bottom)) !important;
}
